import React from "react"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"

import { GeneralForm } from "@components/Form"
import { splitText } from "@utils"

import { Hero } from "@components/Hero"

const NotFoundPage = ({ data }) => {
  const { themeColor } = usePageSetup({ themeColor: "bg-salmon", displayGeneralForm: false })

  return (
    <Layout>
      <Seo title="404" description="Page not found." />
      {/* HERO */}
      <section className={themeColor}>
        <Hero>
          <Hero.Title>
            <div className="lg:grid grid-cols-10">
              <div className="col-span-7">
                <h1
                  data-aos="stagger"
                  dangerouslySetInnerHTML={{
                    __html: splitText({
                      "text-white": "The page you are",
                      "text-gray-darkest": "looking for cannot be found.",
                    }),
                  }}
                />
              </div>
            </div>
          </Hero.Title>
        </Hero>
      </section>

      <main>
        {/* GENERAL FORM */}
        <section className="pt-clamp-18-44 pb-clamp-15-25">
          <div className="container flex xl:justify-center">
            <div className="xl:w-10/12">
              <div style={{ maxWidth: "365px" }}>
                <h2 data-aos="stagger" className="text-title">
                  <span>Let’s</span> <span>chat.</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="container mt-24">
            <div className="xl:w-10/12 mx-auto">
              <GeneralForm />
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default NotFoundPage
